import { GetTasteMixerArtworksRequest } from 'shared/types/Search'

const authInfo = '/auth/info'
export const authUserSwrKey = '/v2/me'
const myProfile = '/v2/me'
const metadata = '/v2/public/metadata'
const countries = '/v2/public/location/country'

export const makeArtworkDetailsSwrKey = (artworkId: number, isLoggedIn: boolean) => {
  return isLoggedIn ? `/v2/artworks/${artworkId}` : `/v2/public/artworks/${artworkId}`
}

export const makeArtworkApplaudersSwrKey = (
  artworkId: number,
  page: number,
  pageSize: number,
  isLoggedIn: boolean | undefined,
) => {
  if (isLoggedIn === undefined) return null
  const params = `page=${page}&pageSize=${pageSize}`
  const endpoint = isLoggedIn ? `/v2/artworks/${artworkId}/liked` : `/v2/public/artworks/${artworkId}/liked`
  return `${endpoint}?${params}`
}

export const makeUserCollectorsPreviewSwrKey = (username: string, isLoggedIn?: boolean) => {
  if (isLoggedIn === undefined) return null
  // get preview of 4 collectors and total count
  return isLoggedIn
    ? `/v2/users/${username}/collectors?page=1&pageSize=4`
    : `/v2/public/users/${username}/collectors?page=1&pageSize=4`
}

export const makeGettingArtworkMoodboardsPreviewSwrKey = (artworkId: number, isLoggedIn?: boolean) => {
  if (isLoggedIn === undefined) return null
  return isLoggedIn ? `/v2/me/artworks/${artworkId}/moodboards` : `/v2/public/artworks/${artworkId}/moodboards/`
}

export const makeGettingSimilarArtworksSwrKey = (artworkId: number, limit: number, isLoggedIn?: boolean) => {
  if (isLoggedIn === undefined) return null
  return isLoggedIn
    ? `/v2/artworks/${artworkId}/similiar-artworks?limit=${limit}`
    : `/v2/public/artworks/${artworkId}/similiar-artworks?limit=${limit}`
}

export const makeGettingArtworkOtherSwrKey = (artworkId: number, limit: number, isLoggedIn?: boolean) => {
  if (isLoggedIn === undefined) return null
  return isLoggedIn
    ? `/v2/artworks/${artworkId}/other-artworks?limit=${limit}`
    : `/v2/public/artworks/${artworkId}/other-artworks?limit=${limit}`
}

export const makeVideoDetailsSwrKey = (videoId: number, isLoggedIn?: boolean) => {
  if (isLoggedIn === undefined) return null // SWR will not fetch if key is null
  const swrKey = {
    key: isLoggedIn ? `/v2/videos/${videoId}` : `/v2/public/videos/${videoId}`, // SWR caches data based on this key
    videoId,
    isLoggedIn,
  }

  return swrKey
}

export const makeStatesSwrKey = (countryIso?: string) => {
  if (!countryIso) return null
  return `/v2/public/location/state?country=${countryIso}`
}

export const makeOrdersSwrKey = (pageIndex: number, pageSize: number, searchTerm?: string) => {
  return `/v2/orders?page=${pageIndex + 1}&pageSize=${pageSize}&search=${searchTerm || ''}`
}

export const makeOrderDetailsSwrKey = (orderId: number) => {
  return `/v2/me/orders/${orderId}`
}

export const makeSaleDetailsSwrKey = (orderId: number) => {
  return `/store/sale/orders/${orderId}`
}

export const makeGettingMyNonLinkedVideosSwrKey = (pageSize: number) => (pageIndex: number) => {
  return {
    page: pageIndex + 1,
    pageSize,
    swrKeys: `/v2/me/videos?page=${pageIndex + 1}&pageSize=${pageSize}&artworkId=null`,
  }
}

export const makeCitiesSwrKey = (countryIso?: string, stateCode?: string) => {
  if (!countryIso || !stateCode) return null
  return `/v2/public/location/city?country=${countryIso}&state=${stateCode}`
}

export const makeProfilePreviewSwrKey = (userId?: number) => {
  if (userId == null) return null // cover both cases: userId === null and userId === undefined
  return `/v2/public/users/${userId}/preview`
}

export const makeDiscoverHubSwrKey = (isLoggedIn: boolean | undefined) => {
  if (isLoggedIn === undefined) return null // SWR will not fetch if key is null
  return isLoggedIn ? '/search/discovery/me/cards' : '/search/discovery/public/cards'
}

export const makeGetVideosSwrKey = (
  pageIndex: number,
  username: string,
  isLoggedIn: boolean,
  isCurrentUser: boolean,
  pageSize: number,
) => {
  if (isLoggedIn && isCurrentUser)
    return { page: pageIndex + 1, swrKeys: `/v2/me/videos?page=${pageIndex + 1}&pageSize=${pageSize}` }
  if (isLoggedIn && !isCurrentUser)
    return {
      page: pageIndex + 1,
      pageSize,
      username,
      swrKeys: `/v2/users/videos?username=${username}&page=${pageIndex + 1}&pageSize=${pageSize}`,
    }

  return {
    page: pageIndex + 1,
    pageSize,
    username,
    swrKeys: `/v2/public/users/videos?username=${username}&page=${pageIndex + 1}&pageSize=${pageSize}`,
  }
}

export const makeGettingTasteMixerResultSwrKey = (isLoggedIn: boolean, query: GetTasteMixerArtworksRequest) => {
  const key: GetTasteMixerArtworksRequest & { isLoggedIn: boolean } = {
    ...query,
    isLoggedIn,
  }

  return key
}

export const makeFavoriteProfilesSwrKey = (userId: number) => {
  return `/v2/users/${userId}/top-profiles`
}

export const makeUserDetailsSwrKey = (userId?: number | null, isLoggedIn?: boolean) => {
  if (userId === undefined) return null
  if (isLoggedIn === undefined) return null
  return isLoggedIn ? `/v2/users/profile?userId=${userId}` : `/v2/public/users/profile?userId=${userId}`
}

export const makeUserDetailsByUsernameSwrKey = (username?: string | null, isLoggedIn?: boolean) => {
  if (username === undefined) return null
  if (isLoggedIn === undefined) return null
  return isLoggedIn ? `/v2/users/profile?username=${username}` : `/v2/public/users/profile?username=${username}`
}

export const makeRecentArticleSwrKey = (articleId: number, displayDate: string, limit: number) => {
  return `/articles/recent?id=${articleId}&displayDate=${displayDate}&limit=${limit}`
}

export const makeDiscoverHubItemsSwrKey = (
  hubId: number,
  limit: number,
  randomProp: number,
  lastArtworkId?: number,
  isLoggedIn?: boolean,
) => {
  if (isLoggedIn === undefined) return null
  const params = `limit=${limit}${lastArtworkId ? `&lastCardContentId=${lastArtworkId}` : ''}`
  const endpoint = isLoggedIn ? `/search/discovery/me/cards/${hubId}` : `/search/discovery/public/cards/${hubId}`
  return `${endpoint}?${params}&random=${randomProp}`
}

export const makeArtworksToWatchSwrKey = (
  userId: number,
  limit: number,
  lastArtworkId?: number,
  isLoggedIn?: boolean,
) => {
  if (isLoggedIn === undefined) return null
  const params = `limit=${limit}${lastArtworkId ? `&lastArtworkId=${lastArtworkId}` : ''}`
  const endpoint = isLoggedIn
    ? `/search/discovery/me/taste-maker/${userId}/artworks-to-watch`
    : `/search/discovery/public/taste-maker/${userId}/artworks-to-watch`
  return `${endpoint}?${params}`
}

export const makeGettingSaleListSwrKey = (
  salesType: 'all' | 'cohart' | 'outsideCohart',
  page: number,
  pageSize: number,
) => {
  const params = `page=${page}&pageSize=${pageSize}`
  let endpoint = '/store/sale/sales'
  if (salesType === 'cohart') endpoint = `/store/sale/orders`
  if (salesType === 'outsideCohart') endpoint = '/store/sale/outside-cohart' // TODO: update later
  return `${endpoint}?${params}`
}

export const makeTrendingMoodboardsSwrKey = (page: number, pageSize: number, isLoggedIn?: boolean) => {
  if (isLoggedIn === undefined) return null

  const params = `page=${page}&pageSize=${pageSize}`
  const endpoint = isLoggedIn
    ? '/search/discovery/me/trending-moodboards'
    : '/search/discovery/public/trending-moodboards'

  return `${endpoint}?${params}`
}

export const makeNewArtworksThisWeekSwrKey = (limit: number, isLoggedIn?: boolean, isForSale = false) => {
  if (isLoggedIn === undefined) return null

  const params = `limit=${limit}&isForSale=${isForSale}`
  const endpoint = isLoggedIn
    ? `/search/discovery/me/artworks-this-week`
    : `/search/discovery/public/artworks-this-week`

  return `${endpoint}?${params}`
}

export const makeDiscoveryByTopicsArtworksSwrKey = (
  topicId: number,
  limit: number,
  lastTopicContentId?: number,
  isLoggedIn?: boolean,
) => {
  if (isLoggedIn === undefined) return null

  const params = `limit=${limit}${lastTopicContentId ? `&lastTopicContentId=${lastTopicContentId}` : ''} `
  const endpoint = isLoggedIn ? `/search/discovery/me/topics/${topicId}` : `/search/discovery/public/topics/${topicId}`

  return `${endpoint}?${params}`
}

export const makeDiscoveryByTopicsArtistsSwrKey = (
  page: number,
  pageSize: number,
  topicId: number,
  isLoggedIn?: boolean,
) => {
  if (isLoggedIn === undefined) return null

  const params = `page=${page}&pageSize=${pageSize}`
  const endpoint = isLoggedIn
    ? `/search/discovery/me/topics/${topicId}/featuring-artists`
    : `/search/discovery/public/topics/${topicId}/featuring-artists`

  return `${endpoint}?${params}`
}

export const makeFeaturedVerticalArtistsSwrKey = (
  page: number,
  pageSize: number,
  cardId: number,
  isLoggedIn?: boolean,
) => {
  if (isLoggedIn === undefined) return null

  const params = `page=${page}&pageSize=${pageSize}`
  const endpoint = isLoggedIn
    ? `/search/discovery/me/cards/${cardId}/featuring-artists`
    : `/search/discovery/public/cards/${cardId}/featuring-artists`

  return `${endpoint}?${params}`
}

export const makeFeaturedArtworksSwrKey = (limit: number, isLoggedIn?: boolean) => {
  if (isLoggedIn === undefined) return null

  const params = `limit=${limit}`
  const endpoint = isLoggedIn ? `/search/discovery/me/featured-artworks` : `/search/discovery/public/featured-artworks`

  return `${endpoint}?${params}`
}

export const makeTastemakerMadeForMoodboardsSwrKey = (userId: number, isLoggedIn?: boolean) => {
  if (isLoggedIn === undefined) return null

  return isLoggedIn
    ? `/search/discovery/me/taste-maker/${userId}/moodboards`
    : `/search/discovery/public/taste-maker/${userId}/moodboards`
}

export const makeTastemakerTopArtistsSwrKey = (userId: number, isLoggedIn?: boolean) => {
  if (isLoggedIn === undefined) return null

  return isLoggedIn
    ? `/search/discovery/me/taste-maker/${userId}/top-artists`
    : `/search/discovery/public/taste-maker/${userId}/top-artists`
}

export const makeMomentsSwrKey = (page: number, pageSize: number, username: string, isLoggedIn?: boolean) => {
  if (isLoggedIn === undefined) return null

  const params = `page=${page}&pageSize=${pageSize}&username=${username}`
  const endpoint = isLoggedIn ? `/v2/users/moments` : `/v2/public/users/moments`

  return `${endpoint}?${params}`
}

export const makeArtworkSavedDataSwrKey = (
  artworkId: number,
  page: number,
  pageSize: number,
  isLoggedIn: boolean | undefined,
) => {
  if (isLoggedIn === undefined) return null
  const params = `page=${page}&pageSize=${pageSize}`
  const endpoint = isLoggedIn ? `/v2/artworks/${artworkId}/saved` : `/v2/public/artworks/${artworkId}/saved`
  return `${endpoint}?${params}`
}

export const makeDiscoverCategorySwrKey = (page: number, pageSize: number) => {
  const params = `page=${page}&pageSize=${pageSize}`
  const endpoint = '/search/discovery/public/categories'
  return `${endpoint}?${params}`
}

export const makeArtistsToWatchSwrKey = (page: number, pageSize: number, isLoggedIn: boolean | undefined) => {
  if (isLoggedIn === undefined) return null
  const params = `page=${page}&pageSize=${pageSize}`
  const endpoint = isLoggedIn ? `/search/discovery/me/artists-to-watch` : `/search/discovery/public/artists-to-watch`
  return `${endpoint}?${params}`
}

export const makeSearchByImageSwrKey = (isLoggedIn: boolean) => {
  const endpoint = isLoggedIn ? `/search/me/artworks/image-search` : `/search/public/artworks/image-search`
  return endpoint
}

export const makeLimitedEditionSwrKey = (
  page: number,
  pageSize: number,
  isForSale = false,
  isLoggedIn: boolean | undefined,
) => {
  if (isLoggedIn === undefined) return null
  const params = `page=${page}&pageSize=${pageSize}&isForSale=${isForSale}`
  const endpoint = isLoggedIn ? `/search/discovery/me/limited-artworks` : `/search/discovery/public/limited-artworks`
  return `${endpoint}?${params}`
}

export const makeLimitedEditionArtistsSwrKey = (limit = 25, isLoggedIn: boolean | undefined) => {
  if (isLoggedIn === undefined) return null
  const params = `limit=${limit}`
  const endpoint = isLoggedIn
    ? `/search/discovery/me/limited-artwork-artists`
    : `/search/discovery/public/limited-artwork-artists`
  return `${endpoint}?${params}`
}

export const makeFeaturedCategorySwrKey = (
  slug: string,
  limit: number,
  randomProp: number,
  isForSale: boolean,
  lastArtworkId?: number,
  isLoggedIn?: boolean,
) => {
  if (isLoggedIn === undefined) return null
  const params = `limit=${limit}${lastArtworkId ? `&lastCardContentId=${lastArtworkId}` : ''}&isForSale=${isForSale}`
  const endpoint = isLoggedIn ? `/search/discovery/me/cards/${slug}` : `/search/discovery/public/cards/${slug}`
  return `${endpoint}?${params}&random=${randomProp}`
}

export const makeFeaturedCuratorArtworksSwrKey = (
  userId: number,
  page = 1,
  pageSize = 15,
  isForSale: boolean,
  isLoggedIn?: boolean,
) => {
  if (isLoggedIn === undefined) return null
  const params = `page=${page}&pageSize=${pageSize}&isForSale=${isForSale}`
  const endpoint = isLoggedIn
    ? `/search/discovery/me/taste-maker/${userId}/artworks`
    : `/search/discovery/public/taste-maker/${userId}/artworks`
  return `${endpoint}?${params}`
}

export const makeFeaturedArtistArtworksSwrKey = (
  userId: number,
  page = 1,
  pageSize = 15,
  isForSale: boolean,
  isLoggedIn?: boolean,
) => {
  if (isLoggedIn === undefined) return null
  const params = `page=${page}&pageSize=${pageSize}&isForSale=${isForSale}`
  const endpoint = isLoggedIn
    ? `/search/discovery/me/discover-featured-artists/${userId}/artworks`
    : `/search/discovery/public/discover-featured-artists/${userId}/artworks`
  return `${endpoint}?${params}`
}

export const makeTrendingArtworksSwrKey = (parameters: {
  session: number
  isForSale: boolean
  page?: number
  pageSize?: number
  isLoggedIn?: boolean
}) => {
  const { session, isForSale, page = 1, pageSize = 15, isLoggedIn } = parameters

  if (isLoggedIn === undefined) return null

  const params = `page=${page}&pageSize=${pageSize}&isForSale=${isForSale}&session=${session}`
  const endpoint = isLoggedIn
    ? `/search/discovery/me/trending-artworks-new`
    : `/search/discovery/public/trending-artworks-new`

  return `${endpoint}?${params}`
}

export const makeArtistsInUserAreaSwrKey = (countryIso: string, limit = 30, isLoggedIn?: boolean) => {
  const params = `limit=${limit}&countryIso=${countryIso}`
  if (isLoggedIn === undefined) return null

  const endpoint = isLoggedIn ? `/search/discovery/me/artists-in-area` : `/search/discovery/public/artists-in-area`

  return `${endpoint}?${params}`
}

export const makeManagedArtistsSwrKey = (username: string, page = 1, pageSize = 15, isLoggedIn?: boolean) => {
  if (isLoggedIn === undefined) return null

  const params = `page=${page}&pageSize=${pageSize}&username=${username}`
  const endpoint = isLoggedIn ? `/v2/users/assigned-artists` : `/v2/public/users/assigned-artists`

  return `${endpoint}?${params}`
}

export const makeFollowingArtistArtworksSwrKey = (page = 1, pageSize = 15) => {
  const params = `page=${page}&pageSize=${pageSize}`
  const endpoint = `/search/discovery/me/following-artists-artworks`

  return `${endpoint}?${params}`
}

export const makeGettingTopCollectorForLinkInBioPreviewSwrKey = (artistUsername: string, isLoggedIn?: boolean) => {
  const swrKey = isLoggedIn
    ? `/v2/link-in-bio/users/artist/collectors?username=${artistUsername}`
    : `/v2/link-in-bio/public/artist/collectors?username=${artistUsername}`
  return swrKey
}

export const makeGettingCelebrityDropBannerSwrKey = (limit = 10) => {
  return `/search/discovery/public/celebrity-drops?limit=${limit}`
}

const swrKeys = {
  authInfo,
  myProfile,
  metadata,
  countries,
}

export default swrKeys
